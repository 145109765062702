<template>
  <div id="app">
    <!--<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
	-->

	<Header/>
	<main>
		<router-view/>
	</main>
  </div>
</template>

<script>
	// Vue principale de l'application

	import Header from "./components/Header.vue"
	
	export default {
		name: 'App',
		components: {
			Header,
		}
	}
</script>

<style>

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		/*color: #2c3e50;*/
	}

	main {
		max-width: 1500px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 3em;
		padding: auto;
	}

	body {
		background: linear-gradient(#2e8ad1, #c5edfc) fixed;
	}

</style>
