<template>
	<b-container>
		<b-carousel
			id="carousel-1"
			v-model="slide"
			:interval="4000"
			controls
			indicators
			background="#ababab"
			img-width="1024"
			img-height="480"
			style="text-shadow: 1px 1px 2px #333;"
			@sliding-start="onSlideStart"
			@sliding-end="onSlideEnd"
		>

			<b-carousel-slide
				caption="Découvrez la région vu du ciel"
				text="Un réel plaisir de prendre de la hauteur"
				img-src="@/assets/img/MCR1.jpg"
			></b-carousel-slide>

			<b-carousel-slide
				caption="Formations"
				text="Profitez d'une formation sur mesure"
				img-src="@/assets/img/pioneer300_1_short.jpg"
			></b-carousel-slide>

			<b-carousel-slide
				caption="Brevet"
				text="Passez votre brevet de pilote ULM"
				img-src="@/assets/img/sensation_short.png"
			></b-carousel-slide>

			<b-carousel-slide
				caption="Vol d'initiation"
				text="Essayer le pilotage d'un ULM"
				img-src="@/assets/img/pioneer300_3_short.jpg"
			></b-carousel-slide>
		</b-carousel>

		<br/>
		<h1 class=display-5>Prestations principales</h1>
		<br/>
		<b-card-group deck>
			<CardPrestation name="Brevet" description="Passez votre brevet de pilote ULM" price="Tarif sur mesure" link="/formation/brevet" img="sensation_short.png"/>
			<!--<CardPrestation name="REV" description="Stage de remise en vol" price="60"/>-->
			<CardPrestation name="Baptême" description="Profitez de la sensations unique du vol en ULM" price="A partir de 95 €" link="/vol-decouverte/bapteme" img="pioneer300_1_short.jpg"/>
			<CardPrestation name="Vol d'initiation" description="Essayez le pilotage d'un ULM" price="A partir de 135 €" link="/vol-decouverte/vol-initiation" img="pioneer300_3_short.jpg"/>
		</b-card-group>
		<br/>
	</b-container>
</template>

<script>
// @ is an alias to /src
import CardPrestation from '@/components/CardPrestation.vue'

export default {
	name: 'Home',
	components: {
		CardPrestation
	}
}
</script>

<style scoped>
	.card-deck {
		margin-left: auto;
		margin-right: auto;
		max-width: max-content;
	}

	#carouselExampleControls {
		max-width: 100%;
		max-height: 10em;
		color: red;
	}

	h3 {
		color: red;
	}

</style>


