import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: "ULM Accro - ULM à Nantes"
		}
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
		meta: {title: "ULM Accro - Qui suis-je ?"}
  },
	{
		path: '/tarifs',
		name: 'Tarifs',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "tarifs" */ '../views/Tarifs.vue'),
		meta: {title: "ULM Accro - Tarifs"}
	},
	{
		path: '/contact',
		name: 'Contact',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
		meta: {title: "ULM Accro - Contact"}

	},
	{
		path: '/service/:service_name',
		name: 'Service',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "service" */ '../views/Service.vue'),
		meta: {title: "ULM Accro - Service"}
	},
	{
		path: '/formation/brevet',
		name: 'Brevet',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "brevet" */ '../views/Prestations/Brevet.vue'),
		meta: {title: "ULM Accro - Brevet"}
	},
	{
		path: '/formation/conversion-avion-ulm',
		name: 'Avion-ULM',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "brevet" */ '../views/Prestations/ConversionAvion.vue'),
		meta: {title: "ULM Accro - Conversion Avion->ULM"}
	},
	{
		path: '/formation/remise-en-vol',
		name: 'REV',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "rev" */ '../views/Prestations/REV.vue'),
		meta: {title: "ULM Accro - Remise en vol"}
	},
	{
		path: '/formation/emport-passager',
		name: 'EmportPassager',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "perfectionnement" */ '../views/Prestations/EmportPassager.vue'),
		meta: {title: "ULM Accro - Emport passager"}
	},
	{
		path: '/formation/vol-mise-en-garde',
		name: 'VolMiseEnGarde',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "perfectionnement" */ '../views/Prestations/VolMiseEnGarde.vue'),
		meta: {title: "ULM Accro - Vol de mise en garde"}
	},
	{
		path: '/formation/prorogation-sep-avion',
		name: 'SEP',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "perfectionnement" */ '../views/Prestations/SEP.vue'),
		meta: {title: "ULM Accro - Vol de mise en garde"}
	},
	{
		path: '/vol-decouverte/bapteme',
		name: 'Bapteme',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "bapteme" */ '../views/Prestations/Bapteme.vue'),
		meta: {title: "ULM Accro - Baptemes"}
	},
	{
		path: '/vol-decouverte/vol-initiation',
		name: 'VI',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "vi" */ '../views/Prestations/VI.vue'),
		meta: {title: "ULM Accro - Vol d'initiation"}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.afterEach((to) => {
	Vue.nextTick(() => {
		document.title = to.meta.title || "ULM Accro";
	});
});


export default router
