<template>
	<b-card
		:title="name"
		:img-src="require(`@/assets/img/${img}`)"
		img-alt="Image" img-top
		style="max-width: 20rem;"
	>
		<b-card-text>{{ description }}</b-card-text>
		<template #footer>
			{{ price }}
		</template>
		<!--<b-card-footer>{{ price }}</b-card-footer>-->
		<!--<b-button style="margin-bottom:1em;" class="btn btn-primary stretched-link" href="#" variant="primary">Voir la prestation</b-button>-->
		<router-link :to="link" class="stretched-link"></router-link>
	</b-card>
</template>

<script>
	export default {
		name: 'CardPrestation',
		props: {
			name: String,
			description: String,
			price: String,
			link: String,
			img: String
		}
	}
</script>

<style scoped>
</style>
